import {
  ComponentRef,
  Directive,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewContainerRef,
  effect,
  inject,
  isDevMode,
  reflectComponentType,
} from '@angular/core';
import { CCASequenceStep, SequenceSummaryValue } from '../sequence';
import { SequenceStore } from '../sequence-store';

@Directive({
  standalone: true,
  selector: `[ccaSequenceSideSummary]`,
})
export class SequenceSideSummaryDirective implements OnDestroy, OnInit {
  private readonly store = inject<SequenceStore>(SequenceStore);
  private readonly injector = inject(Injector);
  private readonly viewRef = inject(ViewContainerRef);

  @Input({ required: true, alias: 'ccaSequenceSideSummary' })
  type: Type<unknown> | null = null;
  componentRef: ComponentRef<unknown> | null = null;

  constructor() {
    effect(
      () => {
        this.setSummaryValues(this.store.summaryValues());
        this.setCompletedSteps(this.store.completedSteps());
      },
      {
        // When a component gets destroyed ( by viewRef.clear() ), it is possible that that causes a event which in turn updates our sequenceStore
        // this has no effect further on our application, so it is safe to allow signalWrites within this effect.
        allowSignalWrites: true,
      },
    );
  }

  ngOnInit(): void {
    if (this.type) {
      this.viewRef.clear();
      this.componentRef = this.viewRef.createComponent(this.type, {
        injector: this.injector,
      });

      this.setSummaryValues(this.store.summaryValues());
    }
  }

  setSummaryValues(steps: SequenceSummaryValue[]) {
    if (this.componentRef) {
      const reflectionType = reflectComponentType(
        this.componentRef.componentType,
      );

      if (
        reflectionType?.inputs?.find((x) => x.templateName === 'summaryValues')
      ) {
        this.componentRef?.setInput('summaryValues', steps);
      } else if (isDevMode()) {
        console.warn(
          `summary component does not have a input of summaryValues`,
        );
      }
    }
  }

  setCompletedSteps(steps: CCASequenceStep[]) {
    if (this.componentRef) {
      const reflectionType = reflectComponentType(
        this.componentRef.componentType,
      );

      if (
        reflectionType?.inputs?.find((x) => x.templateName === 'completedSteps')
      ) {
        this.componentRef?.setInput('completedSteps', steps);
      } else if (isDevMode()) {
        console.warn(
          `summary component does not have a input of completedSteps`,
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.viewRef.clear();
    this.componentRef?.destroy();
    this.componentRef = null;
  }
}
